<template>
    <v-data-table
        :headers="headers"
        :items="entities"
        class="elevation-1"
        disable-sort
        :loading="loading"
        :server-items-length="entitiesCount"
        :items-per-page="itemsPerPage"
        :footer-props="{ 'itemsPerPageOptions': [10, 15, 30] }"
        @pagination="paginate"
    >
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>Пользователи</v-toolbar-title>
                <v-spacer />
                <v-btn color="primary" @click="create">
                    <v-icon>mdi-plus</v-icon> Добавить
                </v-btn>
            </v-toolbar>
        </template>
        <template v-slot:[`item.name`]="{ item }">
            {{ item.firstname }} {{ item.lastname }}
        </template>
        <template v-slot:[`item.phone`]="{ item }">
            <span class="title">{{ item.phone }}</span>
        </template>
        <template v-slot:[`item.admin`]="{ item }">
            <span :class="`true-false-label ${getBadge(item.admin)}`">
                &nbsp;{{item.admin ? 'Да' : 'Нет' }}&nbsp;
            </span>
        </template>
        <template v-slot:[`item.partner`]="{ item }">
            <span :class="`true-false-label ${getBadge(item.partner)}`">
                &nbsp;{{item.partner ? 'Да' : 'Нет' }}&nbsp;
            </span>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
            {{ item.created_at | moment('DD.MM.YYYY HH:mm') }}
        </template>
        <template v-slot:[`item.enabled`]="{ item }">
            <span :class="`true-false-label ${getBadge(item.enabled)}`">
                &nbsp;{{item.enabled ? 'Да' : 'Нет' }}&nbsp;
            </span>
        </template>
        <template v-slot:[`item.open`]="{ item }">
            <a :href="`/users/${item.id}/detail`">
                <v-icon color="primary">mdi-eye</v-icon>
            </a>
        </template>
    </v-data-table>
</template>

<script>
    import store from '../../store';
    import { mapState } from 'vuex';

    export default {
        name: 'UsersList',
        components: {
        },
        data: () => ({
            page: 1,
            itemsPerPage: 15,
            loading: true,
        }),
        computed: {
            ...mapState('users', ['entities', 'entitiesCount']),
            ...mapState('auth', ['user']),
            isAdmin() {
                return this.user.admin;
            },
            headers() {
                if(this.isAdmin) {
                    return [
                        { text: 'Дата', value: 'created_at' },
                        { text: 'Телефон', value: 'phone' },
                        { text: 'Админ', value: 'admin' },
                        { text: 'Партнер', value: 'partner' },
                        { text: 'Имя', value: 'name' },
                        { text: 'Активен', value: 'enabled' },
                        { text: '', align: 'right', value: 'open' },
                    ]
                }
                return [
                    { text: 'Дата', value: 'created_at' },
                    { text: 'Телефон', value: 'phone' },
                    { text: 'Имя', value: 'name' },
                    { text: '', align: 'right', value: 'open' },
                ]
            }
        },
        async mounted() {
            await this.fetch();
        },
        methods: {
            async fetch() {
                this.loading = true;
                await store.dispatch('users/fetch', { page: this.page, itemsPerPage: this.itemsPerPage });
                this.loading = false;
            },
            async paginate({ page, itemsPerPage }) {
                this.page = page;
                this.itemsPerPage = itemsPerPage;
                await this.fetch();
            },
            getBadge (status) {
                return status ? 'success' : 'error';
            },
            async create() {
                await this.$router.push({ name: 'users-create' });
            },
            async open(id) {
                await this.$router.push({ name: 'users-detail', params: { id } });
            }
        }
    }
</script>

<style scoped>
    .title {
        font-size: 16px !important;
        line-height: 18px;
        font-weight: 700;
    }
</style>